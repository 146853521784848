<template lang="pug">
  v-tabs(color="primary" v-model="activeTab" background-color="transparent" height="40px").app-tabs
    v-tab(v-for="tab in tabs" :key="tab.name" @click="switchTab(tab.value)")
      div {{ tab.label }}
        btn(small).ml-2.tab-count {{ tab.count }}
</template>

<script>
import { FEEDBACK_LIST_TABS } from '../core/feedback-const'

export default {
  name: 'FeedbackTabs',

  props: {
    counts: Object
  },

  data: () => ({
    activeTab: 0
  }),

  computed: {
    tabs() {
      return [
        {
          name: FEEDBACK_LIST_TABS.UNPROCESSED,
          label: 'Unprocessed',
          count: this.counts[FEEDBACK_LIST_TABS.UNPROCESSED],
          value: FEEDBACK_LIST_TABS.UNPROCESSED,
        },
        {
          name: FEEDBACK_LIST_TABS.GOOD_CLOSED,
          label: 'Good closed',
          count: this.counts[FEEDBACK_LIST_TABS.GOOD_CLOSED],
          value: FEEDBACK_LIST_TABS.GOOD_CLOSED
        },
        {
          name: FEEDBACK_LIST_TABS.BAD_CLOSED,
          label: 'Bad closed',
          count: this.counts[FEEDBACK_LIST_TABS.BAD_CLOSED],
          value: FEEDBACK_LIST_TABS.BAD_CLOSED
        }
      ]
    },
  },

  created() {
    this.setTabByQuery()
  },

  methods: {
    async switchTab(value) {
      if (this.$route.query.tab === value) return

      await this.$router.replace({
        query: {
          ...this.$route.query,
          tab: value,
          page: 1
        }
      })
      this.$emit('change')
    },

    setTabByQuery() {
      let name = this.$route.query.tab
      let tabIndex = this.tabs.findIndex(tab => tab.value === name)
      if (tabIndex < 0) return
      this.activeTab = tabIndex
    }
  },

  components: {
    btn: () => import('@/components/global/BtnCustom.vue')
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/tabsNew";
</style>
